import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";


export default function AdaAlert(props){

    return (<SweetAlert
        warning
        showCancel
        show={props.modalShow}
        confirmBtnText={props.confirmBtnText}
        cancelBtnText={props.cancelBtnText}
        confirmBtnBsStyle={props.confirmBtnBsStyle}
        cancelBtnBsStyle={props.cancelBtnBsStyle}
        title={props.title}
        onConfirm={props.handlerYes}
        onCancel={props.handlerNo}
    >
        {props.text}
    </SweetAlert>);
}