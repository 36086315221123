import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const {REACT_APP_DOMAIN} = process.env;

export const ProtectedRoute = ({ authData, children }) => {
    return (
        <div>
            {
            // cookies.get('jwtToken') 
            true
            ? (
                <Fragment>{children}</Fragment>
            ) : (
                window.open('https://portal' + '.' + REACT_APP_DOMAIN + '/login', "_parent")
            )}
        </div>
    );
};
