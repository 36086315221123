const actionsPermission = [
    {
        "label": "ALL",
        "value": "ALL"
    },
    {
        "label": "READ",
        "value": "READ"
    },
    {
        "label": "INSERT",
        "value": "INSERT"
    },
    {
        "label": "UPDATE",
        "value": "UPDATE"
    },
    {
        "label": "DELETE",
        "value": "DELETE"
    },
    {
        "label": "PRINT",
        "value": "PRINT"
    },
    {
        "label": "VALIDATE",
        "value": "VALIDATE"
    }
]
export default actionsPermission;
