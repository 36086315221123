import React from "react";
import AdaAlert from "./ada.alert";
import IntlMessages from "../../../helpers/intlMessages";


export default function AdaAlertDelete(props){

    return (<AdaAlert
        warning
        showCancel
        modalShow={props.modalShow}
        confirmBtnText={<IntlMessages id="common.popup.delete.button.yes"/>}
        cancelBtnText={<IntlMessages id="common.popup.delete.button.no"/>}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title={<IntlMessages id="common.popup.delete.message"/>}
        handlerNo={props.handlerNo}
        handlerYes={props.handlerYes}
    >
        {props.text}
    </AdaAlert>);
}