import React, {useEffect, useState} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col} from 'reactstrap';
import classnames from 'classnames';
import axios from "axios";
import Container from "react-bootstrap/Container";
import SweetAlert from "react-bootstrap-sweetalert";
import {getConfigObject} from "@adaming/ada-react-component";

export default function UserDetail({hideDetails, selectedElement}) {
    const [activeTab, setActiveTab] = useState('1');
    const [user, setUser] = React.useState({});
    const [roles, setRoles] = React.useState([]);
    const [basicTitleAlert, setBasicTitleAlert] = useState(false);
    const [selectedRole, setSelectedRole] = useState({});
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        console.log('Hide user details :', selectedElement)
        if (!hideDetails) {
            let idSelectedElement = "";
            if (selectedElement !== null && selectedElement !== undefined) {
                idSelectedElement = selectedElement.id;
                console.log('User Id for details :', idSelectedElement)
                axios.get(`${process.env.REACT_APP_API_URL}/iam-service/users/${idSelectedElement}`, getConfigObject()).then(res => {
                    console.log("getUser by ID rest service http : ", res.data);
                    setUser(res.data);
                    if (res.data.roles !== undefined) {
                        setRoles(res.data.roles)
                    }
                });
            }
        }
    }, [hideDetails])

    return (
        (!hideDetails && <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '1'})}
                            onClick={() => {
                                toggle('1');
                            }}
                            style={{cursor: "pointer"}}
                        >
                            Details
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '2'})}
                            onClick={() => {
                                toggle('2');
                            }}
                            style={{cursor: "pointer"}}
                        >
                            Roles
                        </NavLink>
                    </NavItem>

                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <Card body>
                                    <CardTitle></CardTitle>
                                    <CardText>
                                        <Container>
                                            <Row>
                                                <Col xs={6}><span
                                                    className="chip">Utlisateur :</span> {user.lastName} {user.firstName}
                                                </Col>
                                                <Col xs={6}><span
                                                    className="chip">Administrateur:</span> {user.administrator = 'true' ? 'Oui' : 'Non'}
                                                </Col>
                                            </Row>
                                            <br/>
                                            <br/>
                                            <Row>
                                                <Col xs={6}>
                                                <span
                                                    className="chip">Date de modification :</span> {user.lastModifiedDate} par {user.lastModifiedUser}
                                                </Col>
                                                <Col xs={6}>
                                                <span
                                                    className="chip">Date de modification :</span> {user.lastModifiedDate} par {user.lastModifiedUser}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </CardText>
                                </Card>
                            </Col>
                        </Row>

                    </TabPane>
                    <TabPane tabId="2">
                        <Col sm="15">
                            <Card body>
                                <CardText>
                                    <Container>
                                        <p>Cliquer pour plus d'information :</p><br/>
                                        <Row>
                                            {roles.length > 0 ? (roles.map(role => (
                                                <Col
                                                    xs={6}
                                                    body key={role.id}
                                                >
                                                <span
                                                    className="chip"
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => {
                                                        setSelectedRole(role);
                                                        setSelectedPermissions(role.permissions);
                                                        setBasicTitleAlert(!basicTitleAlert);
                                                    }}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    {role.name}
                                                </span>
                                                </Col>
                                            ))) : 'Pas de roles'}
                                        </Row>
                                    </Container>
                                </CardText>
                            </Card>
                        </Col>
                    </TabPane>
                </TabContent>

                {/* Partie modal role*/}
                <SweetAlert
                    title="Role détaille!"
                    show={basicTitleAlert}
                    onConfirm={() =>
                        setBasicTitleAlert(!basicTitleAlert)
                    }
                    confirmBtnCssClass="sweet-alert-confirm-button"
                    cancelBtnCssClass="sweet-alert-cancle-button"
                >
                    <Col>Code : {selectedRole.name}</Col>
                    <Col>libellé : {selectedRole.name}       </Col>
                    <Col>Permission : </Col>
                    {selectedPermissions.map(permission =>
                        <div key={permission.id}>
                            <p>{permission.code} - {permission.name}</p>
                        </div>
                    )}
                </SweetAlert>
            </div>
        )
    );
};
