import React, {Fragment} from "react";
import {AdaButton, AdaInputText, AdaSelect, getOrganizationSelected} from "@adaming/ada-react-component";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import useForm from "react-hook-form";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../helpers/intlMessages";
import actionsPermission from "./data/actionPermissionEnum"
import {editMethod, getAllForSelect, getByIdMethod} from "../../components/services/crud.service";

const context = "permissions";
export default function PermissionForm(props) {

    const id = props.location.pathname.split("/")[2];
    const {register, handleSubmit, errors, setValue} = useForm();
    const [selectApplicationModule, setSelectApplicationModule] = React.useState();
    const [selectActionPermissions, setSelectActionPermissions] = React.useState();
    const [permission, setPermission] = React.useState({});
    const [errorMessage, setErrorMessage] = React.useState("");
    const [applicationModules, setApplicationModules] = React.useState([]);

    let history = useHistory();

    React.useEffect(() => {
        register({name: "code"}, {required: "Ce champ est obligatoire."});
        register({name: "name"}, {required: "Ce champ est obligatoire."});
        register({name: "applicationModule"}, {required: "Ce champ est obligatoire."});
        register({name: "actionPermissions"}, {required: "Ce champ est obligatoire."});
    }, []);

    React.useEffect(() => {
        if (id !== undefined) {
            getByIdMethod(props.host, props.context, setPermission, init, setErrorMessage, id)
        }
    }, [id]);

    React.useEffect(() => {
        getAllForSelect(props.host, 'application_modules', setApplicationModules);
    }, []);

    function init(data) {
        setValue("applicationModule", data.applicationModule);
        setSelectApplicationModule(
            {
                key: data.applicationModule.id,
                label: data.applicationModule.name,
                value: data.applicationModule.id,
                object: data
            });

        let actions = [];
        data.actionPermissions.forEach(item => {
                actions.push({
                    label: item,
                    value: item
                })
            }
        );
        setValue("actionPermissions", data.actionPermissions);
        setSelectActionPermissions(actions);
    };

    const handleApplicationModule = selectedOption => {
        setValue("applicationModule", selectedOption.object);
        setSelectApplicationModule(selectedOption);
    };

    const handleActionPermissions = selectedActions => {
        let listepem = undefined;
        if (selectedActions) {
            listepem = [];
            selectedActions.map(item => listepem.push(item.label));
        }
        setValue("actionPermissions", listepem);
        setSelectActionPermissions(selectedActions);
    };

    const onSubmit = data => {
        if (id !== undefined && id !== "new") {
            data = {...data, "id": id};
        }
        data.organization = getOrganizationSelected().id;
        editMethod(props.host, context, () => history.push("/" + props.context), setErrorMessage, data)
    };


    return (
        <Fragment>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>
                                            <FormGroup>
                                                <IntlMessages
                                                    id="iam.permission.code"
                                                />
                                                <AdaInputText
                                                    name="code"
                                                    defaultValue={permission.code}
                                                    errors={errors}
                                                    innerRef={register}
                                                />

                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages
                                                    id="iam.permission.name"
                                                />
                                                <AdaInputText
                                                    name="name"
                                                    defaultValue={permission.name}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages
                                                    id="iam.permission.applicationModules"
                                                />
                                                <AdaSelect
                                                    name="applicationModule"
                                                    value={selectApplicationModule}
                                                    options={applicationModules}
                                                    onChange={handleApplicationModule}
                                                    innerRef={register}
                                                    errors={errors}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <IntlMessages
                                                    id="iam.permission.actionPermissions"
                                                />
                                                <AdaSelect
                                                    name="actionPermissions"
                                                    isMulti
                                                    value={selectActionPermissions}
                                                    options={actionsPermission}
                                                    onChange={handleActionPermissions}
                                                    innerRef={register}
                                                    errors={errors}
                                                />
                                            </FormGroup>


                                            <FormGroup>
                                                <AdaButton type="sumbit">
                                                    <IntlMessages
                                                        id="iam.common.submit"
                                                    />
                                                </AdaButton>
                                                <AdaButton
                                                    type="sumbit"
                                                    style={{marginLeft: 10}}
                                                    onClick={() => history.push(`/${context}`)}
                                                >
                                                    <IntlMessages
                                                        id="iam.common.cancel"
                                                    />
                                                </AdaButton>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}