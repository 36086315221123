import UserForm from "../views/iam/user/UserForm";
import UserDetail from "../views/iam/user/user.detail";
import {UserColumn} from "../views/iam/user/data/user.column";

const UserRoute = [

    {resource: "users", type:"index",  host :`${process.env.REACT_APP_API_URL}/iam-service`, columns :UserColumn, actions: [], detailComponent : UserDetail},
    {resource: "users", type:"new", host :`${process.env.REACT_APP_API_URL}/iam-service`, component: UserForm},
    {resource: "users", type:"update", host :`${process.env.REACT_APP_API_URL}/iam-service`, component: UserForm},

];

export default UserRoute;