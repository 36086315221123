import applicationmoduleRoute from "./applicationmodule.route";
import businessdomainRoute from "./businessdomain.route";
import permissionRoute from "./permission.route";
import roleRoute from "./role.route";
import userRoute from "./user.route";
import React from "react";
import Maintenance from "../views/pages/error/maintenance";

let IamRoute = [];

if (Array.isArray(applicationmoduleRoute)) {
    IamRoute.push(...applicationmoduleRoute);
}
if (Array.isArray(businessdomainRoute)) {
    IamRoute.push(...businessdomainRoute);
}
if (Array.isArray(permissionRoute)) {
    IamRoute.push(...permissionRoute);
}
if (Array.isArray(roleRoute)) {
    IamRoute.push(...roleRoute);
}
if (Array.isArray(userRoute)) {
    IamRoute.push(...userRoute);
}

IamRoute.push({ path: "/maintenance", component: Maintenance});

export default IamRoute;