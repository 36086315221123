import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";

export const RoleColumn = [
    {
        name: <IntlMessages
            id="iam.role.datatable.column.code"
        />,
        selector: 'code',
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="iam.role.datatable.column.name"
        />,
        selector: 'name',
        sortable: false,
        filtrable: true,
    },
    // {
    //     name: <IntlMessages
    //         id="iam.role.datatable.column.permission"
    //     />,
    //     selector: 'permission.name',
    //     sortable: false,
    //     filtrable: true,
    // },

];