const typeApplicationModule =  [
  {
    "label": "UI",
    "value": "UI"
  },
  {
    "label": "SERVICE",
    "value": "SERVICE"
  },
  {
    "label": "LEGACY",
    "value": "LEGACY"
  }
]
export default typeApplicationModule;
