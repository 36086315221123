import PermissionForm from "../views/iam/permission/permissionForm";
import PermissionDetail from "../views/iam/permission/permission.detail";
import {PermissionColumn} from "../views/iam/permission/data/permission.column";

const PermissionRoute = [

    {resource: "permissions", type:"index",  host :`${process.env.REACT_APP_API_URL}/iam-service`, columns :PermissionColumn, actions: [], detailComponent : PermissionDetail},
    {resource: "permissions", type:"new", host :`${process.env.REACT_APP_API_URL}/iam-service`,component:PermissionForm},
    {resource: "permissions", type:"update", host :`${process.env.REACT_APP_API_URL}/iam-service`,component:PermissionForm},
];

export default PermissionRoute;