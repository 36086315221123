import React, {useEffect, useState} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col} from 'reactstrap';
import classnames from 'classnames';
import axios from "axios";
import Container from "react-bootstrap/Container";
import SweetAlert from "react-bootstrap-sweetalert";
import {getConfigObject} from "@adaming/ada-react-component";

export default function PermissionDetail({hideDetails, selectedElement}) {
    const [activeTab, setActiveTab] = useState('1');
    const [permission, setPermission] = React.useState({});
    const [applicationModules, setApplicationModules] = React.useState([]);
    const [basicTitleAlert, setBasicTitleAlert] = useState(false);
    const [selectedApplicationModules, setSelectedApplicationModules] = useState({});
    const [selectedBusinessDomains, setSelectedBusinessDomains] = useState([]);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        console.log('Hide permission details :', hideDetails)
        if (!hideDetails) {
            let idSelectedElement = "";
            if (selectedElement !== null && selectedElement !== undefined) {
                idSelectedElement = selectedElement.id;
                console.log('Permission Id for details :', idSelectedElement)
                axios.get(`${process.env.REACT_APP_API_URL}/iam-service/permissions/${idSelectedElement}`, getConfigObject()).then(res => {
                    console.log("getPermission by ID rest service http : ", res.data);
                    setPermission(res.data);
                    if (res.data.applicationModules !== undefined) {
                        setApplicationModules(res.data.applicationModules)
                    }
                });
            }
        }
    }, [hideDetails])

    return (
        (!hideDetails && <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === '1'})}
                        onClick={() => {
                            toggle('1');
                        }}
                        style={{cursor: "pointer"}}
                    >
                        Details
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === '2'})}
                        onClick={() => {
                            toggle('2');
                        }}
                        style={{cursor: "pointer"}}
                    >
                        Module applicatif
                    </NavLink>
                </NavItem>

            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <Card body>
                                <CardTitle></CardTitle>
                                <CardText>
                                    <Container>
                                        <Row>
                                            <Col xs={6}>
                                                <span
                                                    className="chip">Permission :</span> {permission.name}
                                            </Col>
                                            <Col xs={6}>
                                                <span
                                                    className="chip">Actions :</span> {permission.actionPermissions}
                                            </Col>
                                        </Row>
                                        <br/>
                                    </Container>
                                </CardText>
                            </Card>
                        </Col>
                    </Row>

                </TabPane>
                <TabPane tabId="2">
                    <Col sm="15">
                        <Card body>
                            <CardText>
                                <Container>
                                    <p>Cliquer pour plus d'information</p><br/>
                                    <Row>
                                        {applicationModules.length > 0 ? (applicationModules.map(applicationModule => (
                                            <p
                                                xs={6}
                                                body key={applicationModule.id}
                                            >
                                                <span
                                                    className="chip"
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => {
                                                        setSelectedApplicationModules(applicationModule);
                                                        selectedBusinessDomains(applicationModule.businessDomains)
                                                        setBasicTitleAlert(!basicTitleAlert)
                                                    }}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                {applicationModule.name}
                                                </span>
                                            </p>
                                        ))) : 'Pas de module applicatif'}

                                    </Row>
                                </Container>
                            </CardText>
                        </Card></Col>
                </TabPane>
            </TabContent>
            {/* Partie modal applicationModule*/}

            <SweetAlert
                title="Application Module détaille!"
                show={basicTitleAlert}
                onConfirm={() =>
                    setBasicTitleAlert(!basicTitleAlert)
                }
                confirmBtnCssClass="sweet-alert-confirm-button"
                cancelBtnCssClass="sweet-alert-cancle-button"
            >
                <Col>Code : sssss</Col>
                <Col>Utilisateur : {selectedApplicationModules.name}       </Col>
                <Col>Permission : sssssss </Col>

                {selectedBusinessDomains.map(businessDomain =>

                    <div key={businessDomain.id}>
                        <p>{businessDomain.code} - {businessDomain.name}</p>
                    </div>
                )}
            </SweetAlert>
        </div>)
    );
};
