import React from "react";
import IntlMessages from "../../../helpers/intlMessages";

export const ActionPermission = [
    {
        "label": "ALL",
        "value": "ALL"
    },
    {
        "label": "READ",
        "value": "READ"
    },
    {
        "label": "WRITE",
        "value": "WRITE"
    },
    {
        "label": "EDIT",
        "value": "EDIT"
    },
    {
        "label": "DELETE",
        "value": "DELETE"
    },
    {
        "label": "PRINT",
        "value": "PRINT"
    }
]

export const UnitType = [
    {
        "label": "NUMBER",
        "value": "nomenclature.common.enum.unit.type.number"
    },
    {
        "label": "PERCENT",
        "value": "nomenclature.common.enum.unit.type.percent"
    }
]

export const UnitCategory = [
    {
        "label": "TIME",
        "value": "nomenclature.common.enum.unit.category.time"
    },
    {
        "label": "DISTANCE",
        "value": "nomenclature.common.enum.unit.category.distance"
    }
]

export const Enums =
    {"E_AP": ActionPermission,
    "E_UT": UnitType,
    "E_UC" : UnitCategory}
;




export function getEnumList(enumCode, resultFunction) {

    const enums = Enums[enumCode];
    var result = [];

    enums.forEach(element => result.push({
        key: element.label,
        label: <IntlMessages id={element.value}/>,
        value: element.label
    }));

    resultFunction(result);

}