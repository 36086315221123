import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";

export const  UserColumn = [

    {
        name: <IntlMessages
            id="iam.user.datatable.column.username"
        />,
        selector: "username",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="iam.user.datatable.column.lastName"
        />,
        selector: "lastName",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="iam.user.datatable.column.firstName"
        />,
        selector: "firstName",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="iam.user.datatable.column.email"
        />,
        selector: "email",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="iam.user.administrator"
        />,
        selector: "administrator",
        sortable: false,
        filtrable: false,
        format: row => (row.administrator === true ? "Oui" : "Non")
    },
    {
        name: <IntlMessages
            id="iam.user.datatable.column.locked"
        />,
        selector: "locked",
        sortable: false,
        filtrable: true,
        format: row => (row.locked === true ? "Oui" : "Non")
    },
    {
        name: <IntlMessages
            id="iam.user.datatable.column.enabled"
        />,
        selector: "enabled",
        sortable: false,
        filtrable: false,
        format: row => (row.enabled === true ? "Oui" : "Non")
    },
    {
        name: <IntlMessages
            id="iam.user.datatable.column.expired"
        />,
        selector: "expired",
        sortable: false,
        filtrable: false,
        format: row => (row.expired === true ? "Oui" : "Non")
    }
];