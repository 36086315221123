
import {BusinessDomainColumn} from "../views/iam/businessdomains/data/businessdomain.column";
import {BusinessDomainEdit} from "../views/iam/businessdomains/data/businessdomain.edit";
import BusinessDomainDetail from "../views/iam/businessdomains/businessdomain.detail";

const BusinessdomainRoute = [

    {resource: "business_domains", type:"index",  host :`${process.env.REACT_APP_API_URL}/iam-service`, columns :BusinessDomainColumn, actions: [], detailComponent : BusinessDomainDetail},
    {resource: "business_domains", type:"new", host :`${process.env.REACT_APP_API_URL}/iam-service`,edit:BusinessDomainEdit},
    {resource: "business_domains", type:"update", host :`${process.env.REACT_APP_API_URL}/iam-service`,edit:BusinessDomainEdit},

];

export default BusinessdomainRoute;