import React, {Fragment} from "react";
import {AdaButton, AdaInputText, AdaSelect, getOrganizationSelected} from "@adaming/ada-react-component";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import useForm from "react-hook-form";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../helpers/intlMessages";
import {editMethod, getAllForSelect, getByIdMethod} from "../../components/services/crud.service";

const context = "roles";
export default function BusinessDomainForm(props) {

    const id = props.location.pathname.split("/")[2];

    const {register, handleSubmit, errors, setValue} = useForm();
    const [isLoading, setIsLoading] = React.useState(false);
    const [role, setRole] = React.useState({});
    const [permissions, setPermissions] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [selectedPermissions, setSelectedPermissions] = React.useState();
    let history = useHistory();


    React.useEffect(() => {
        register({name: "code"}, {required: 'Ce champ est obligatoire.'});
        register({name: "name"}, {required: "Ce champ est obligatoire."});
        register(
            {name: "permissions"},
            {required: "Ce champ est obligatoire."}
        );
    }, []);


    React.useEffect(() => {
        if (id !== undefined) {
            getByIdMethod(props.host, props.context, setRole, init, setErrorMessage, id)
        }
    }, [id]);

    React.useEffect(() => {
        getAllForSelect(props.host, 'permissions', setPermissions);
    }, []);

    function init(data) {
        let permissions = [];
        data.permissions.forEach(item => {
            permissions.push({
                key: item.id,
                label: item.name,
                value: item.id,
                object: item
                })
            }
        );
        setValue("permissions", data.permissions);
        setSelectedPermissions(permissions);
    };

    const onSubmit = data => {
        if (id !== undefined && id !== "new") {
            data = {...data, "id": id};
        }
        data.organization = getOrganizationSelected().id;
        editMethod(props.host, context, () => history.push("/" + props.context), setErrorMessage, data)
    };

    const handleMultiChange = selectedPermissions => {
        let permissions = undefined;
        if (selectedPermissions) {
            permissions = [];
            selectedPermissions.map(item => {
                permissions.push(item.object)
            })
            setValue("permissions", permissions);
        }
        setSelectedPermissions(selectedPermissions);
    };

    return (
        <Fragment>
            {isLoading ? (
                <div>Loading ...</div>
            ) : (
                <div>
                    <div className="row ma-0">
                        <div className="col-sm-12 ptb-15">
                            <div className="roe-card-style">
                                <div className="roe-card-body">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col>
                                                <Label>
                                                    <p style={{color: "red"}}> {errorMessage}</p>
                                                </Label>
                                                <FormGroup>
                                                    <IntlMessages id="iam.role.code"/>
                                                    <AdaInputText
                                                        placeholder=""
                                                        name="code"
                                                        defaultValue={role.code}
                                                        innerRef={register}
                                                        errors={errors}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <IntlMessages id="iam.role.name"/>
                                                    <AdaInputText
                                                        name="name"
                                                        defaultValue={role.name}
                                                        innerRef={register}
                                                        errors={errors}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <IntlMessages id="iam.role.permission"/>
                                                    <AdaSelect
                                                        name="permissions"
                                                        value={selectedPermissions}
                                                        isMulti
                                                        options={permissions}
                                                        onChange={handleMultiChange}
                                                        innerRef={register}
                                                        errors={errors}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <AdaButton type="submit">
                                                        <IntlMessages id="iam.common.submit"/>
                                                    </AdaButton>
                                                    <AdaButton
                                                        style={{marginLeft: 10}}
                                                        onClick={() => history.push("/roles")}
                                                    >
                                                        <IntlMessages id="iam.common.cancel"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </Fragment>
    );
}
