import React, {Fragment} from "react";
import {AdaButton, AdaInputText, AdaSelect, getOrganizationSelected} from "@adaming/ada-react-component";
import {FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import useForm from "react-hook-form";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../helpers/intlMessages";
import typeApplicationModule from "./data/typeApplicationModuleEnum"
import Label from "reactstrap/es/Label";
import {editMethod, getAllForSelect, getByIdMethod} from "../../components/services/crud.service";
import CustomInput from "reactstrap/es/CustomInput";

const context = "application_modules";

export default function ApplicationModuleForm(props) {

    const id = props.location.pathname.split("/")[2];
    const [selectedBusinessDomain, setSeletedBusinessDomain] = React.useState();
    const {register, handleSubmit, errors, setValue, clearError} = useForm();
    const [businessDomains, setBusinessDomains] = React.useState();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [applicationModule, setApplicationModule] = React.useState({});
    const [selectTypeApplication, setSelectTypeApplication] = React.useState();
    const [dataElements, setDataElements] = React.useState([{
        key: "",
        value: ""
    }]);
    let history = useHistory();

    React.useEffect(() => {
        register({name: "code"}, {required: "Ce champ est obligatoire."});
        register({name: "resources"});
        register({name: "name"}, {required: "Ce champ est obligatoire."});
        register({name: "icon"}, {required: "Ce champ est obligatoire."});
        register({name: "applicationType"}, {required: "Ce champ est obligatoire."});
        register({name: "businessDomain"}, {required: "Ce champ est obligatoire."}
        );
        //register({name: "datas"}
        //);
    }, []);

    React.useEffect(() => {
        if (id !== undefined) {
            getByIdMethod(props.host, props.context, setApplicationModule, init, setErrorMessage, id)
        }
    }, [id]);

    React.useEffect(() => {
        getAllForSelect(`${process.env.REACT_APP_API_URL}/iam-service`, 'business_domains', setBusinessDomains);
    }, []);

    /*React.useEffect(() => {
        setValue("datas", dataElements);
    }, [dataElements]);*/


    function init(data) {
        console.log(data);
        let res = undefined;
        if (data.resources) {
            res = "";
            res = data.resources.map(item => {
                res = item + "," + res;
            });
        }
        setSeletedBusinessDomain(
            {
                key: data.businessDomain.id,
                label: data.businessDomain.name,
                value: data.businessDomain.id,
                object: data.businessDomain
            });
        setValue("businessDomain", data.businessDomain);

        setSelectTypeApplication(
            {
                label: data.applicationType,
                value: data.applicationType
            });
        setValue("applicationType", data.applicationType);
    }

    const handleBusinessDomainChange = selectedOption => {
        setValue("businessDomain", selectedOption.object);
        setSeletedBusinessDomain(selectedOption);
    };

    const handleTypeApplication = selectedOption => {
        setValue("applicationType", selectedOption.value);
        setSelectTypeApplication(selectedOption);
    };

    const onSubmit = data => {

        if (id !== undefined && id !== "new") {
            data = {...data, "id": id};
        }
        let res = undefined;
        if (data.resources) {
            let tab = data.resources.split(",")
            if (tab) {
                res = [];
                tab.map(item => res.push(item));
            }
        }
        data.resources = res;
        data.businessDomain = data.businessDomain;
        data.datas = undefined;
        data.organization = getOrganizationSelected().id;
        editMethod(`${process.env.REACT_APP_API_URL}/iam-service`, 'application_modules', () => history.push("/" + props.context), setErrorMessage, data)
    };


    return (
        <Fragment>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>
                                            <FormGroup>
                                                <IntlMessages id="iam.applicationModules.code"/>
                                                <AdaInputText
                                                    placeholder=""
                                                    name="code"
                                                    defaultValue={applicationModule.code}
                                                    innerRef={register}
                                                    errors={errors}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <IntlMessages id="iam.applicationModules.name"/>
                                                <AdaInputText
                                                    name="name"
                                                    defaultValue={applicationModule.name}
                                                    innerRef={register}
                                                    errors={errors}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <IntlMessages id="iam.applicationModules.icon"/>
                                                <AdaInputText
                                                    name="icon"
                                                    defaultValue={applicationModule.icon}
                                                    innerRef={register}
                                                    errors={errors}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <IntlMessages id="iam.applicationModules.resources"/>
                                                <AdaInputText
                                                    placeholder="Séparés par les virgules"
                                                    name="resources"
                                                    defaultValue={applicationModule.resources}
                                                    innerRef={register}
                                                    errors={errors}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <IntlMessages id="iam.applicationModules.businessDomains"/>
                                                <AdaSelect
                                                    name="businessDomain"
                                                    value={selectedBusinessDomain}
                                                    options={businessDomains}
                                                    onChange={handleBusinessDomainChange}
                                                    innerRef={register}
                                                    errors={errors}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <IntlMessages id="iam.applicationModules.type"/>
                                                <AdaSelect
                                                    name="applicationType"
                                                    value={selectTypeApplication}
                                                    options={typeApplicationModule}
                                                    onChange={handleTypeApplication}
                                                    innerRef={register}
                                                    errors={errors}
                                                />
                                            </FormGroup>


                                            <FormGroup>
                                                Avec une validation
                                                <CustomInput
                                                    type="switch"
                                                    id="withValidationBloc"
                                                    name="withValidationBloc"
                                                    defaultChecked={applicationModule.withValidationBloc}
                                                    innerRef={register}
                                                />
                                            </FormGroup>)


                                            <FormGroup>
                                                <AdaButton type="submit">
                                                    <IntlMessages id="iam.common.submit"/>
                                                </AdaButton>
                                                <AdaButton
                                                    style={{marginLeft: 10}}
                                                    onClick={() => history.push(`/${context}`)}
                                                >
                                                    <IntlMessages id="iam.common.cancel"/>
                                                </AdaButton>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
