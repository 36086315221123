import React, {useEffect, useState} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col} from 'reactstrap';
import classnames from 'classnames';
import axios from "axios";
import Container from "react-bootstrap/Container";
import {getConfigObject} from "@adaming/ada-react-component";

export default function BusinessDomainDetail({hideDetails, selectedElement}) {
    const [activeTab, setActiveTab] = useState('1');
    const [businessDomains, setBusinessDomains] = React.useState({});

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        console.log('Hide businessDomains details :', hideDetails)
        if (!hideDetails) {
            let idSelectedElement = "";
            if (selectedElement !== null && selectedElement !== undefined) {
                idSelectedElement = selectedElement.id;
                console.log('BusinessDomains Id for details :', idSelectedElement)
                axios.get(`${process.env.REACT_APP_API_URL}/iam-service/business_domains/${idSelectedElement}`, getConfigObject()).then(res => {
                    console.log("getBusinessDomains by ID rest service http : ", res.data);
                    setBusinessDomains(res.data);
                });
            }
        }
    }, [hideDetails])

    return (
        (!hideDetails && <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === '1'})}
                        onClick={() => {
                            toggle('1');
                        }}
                        style={{cursor: "pointer"}}
                    >
                        Details
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <Card body>
                                <CardTitle></CardTitle>
                                <CardText>
                                    <Container>
                                        <Row>
                                            <p xs={6}><span
                                                className="chip">Utlisateur :</span> {businessDomains.name}
                                            </p>
                                        </Row>
                                        <br/>
                                    </Container>
                                </CardText>
                            </Card>
                        </Col>
                    </Row>

                </TabPane>

            </TabContent>
        </div>)
    );
};
