import React, {Fragment} from "react";
import IntlMessages from "../../../helpers/intlMessages";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {AdaButton} from "@adaming/ada-react-component";

export default function AdaActions({
                                       context,
                                       actionsObj,
                                       selectedRows
                                   }) {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const params = selectedRows ? {"selectedRows" : selectedRows} : {"selectedRows" : null};
    return (
        <Fragment>
            <>
                <AdaButton
                    href={`/${context}/new`}
                    className="c-add-btn ma-5"
                >
                    <i className="fas fa-plus mr-10"></i><IntlMessages id={`iam.common.add`}/>
                </AdaButton>


                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle className="c-action-btn" caret><IntlMessages id={`iam.common.actions`}/>
                    </DropdownToggle>
                    <DropdownMenu>
                        {actionsObj.map((actionElement, i) => (
                            (actionElement.actionHandler &&
                            <DropdownItem
                                key={actionElement.actionName + i}
                                onClick={() => {actionElement.actionHandler(params)}}
                                disabled={actionElement.disabled}
                            >
                                {actionElement.actionName}
                            </DropdownItem>)
                                ||
                            (actionElement.href &&
                                <DropdownItem
                                    key={actionElement.actionName + i}
                                    href={actionElement.href}
                                    disabled={actionElement.disabled}
                                >
                                    {actionElement.actionName}
                                </DropdownItem>)
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </>
        </Fragment>
    );
}