import React, {useEffect, useState} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col} from 'reactstrap';
import classnames from 'classnames';
import axios from "axios";
import Container from "react-bootstrap/Container";
import SweetAlert from "react-bootstrap-sweetalert";
import {getConfigObject} from "@adaming/ada-react-component";

export default function RolesDetail({hideDetails, selectedElement}) {
    const [activeTab, setActiveTab] = useState('1');
    const [roles, setRoles] = React.useState({});
    const [permissions, setPermissions] = React.useState([]);
    const [basicTitleAlert, setBasicTitleAlert] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState({});
    const [selectedApplicationModules, setSelectedApplicationModules] = useState([]);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        console.log('Hide roles details :', hideDetails)
        if (!hideDetails) {
            let idSelectedElement = "";
            if (selectedElement !== null && selectedElement !== undefined) {
                idSelectedElement = selectedElement.id;
                console.log('Roles Id for details :', idSelectedElement)
                axios.get(`${process.env.REACT_APP_API_URL}/iam-service/roles/${idSelectedElement}`, getConfigObject()).then(res => {
                    console.log("getRoles by ID rest service http : ", res.data);
                    setRoles(res.data);
                    if (res.data.permissions !== undefined) {
                        setPermissions(res.data.permissions)
                    }
                });
            }
        }
    }, [hideDetails])

    return (
        (!hideDetails && <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '1'})}
                            onClick={() => {
                                toggle('1');
                            }}
                            style={{cursor: "pointer"}}
                        >
                            Details
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '2'})}
                            onClick={() => {
                                toggle('2');
                            }}
                            style={{cursor: "pointer"}}
                        >
                            Permission
                        </NavLink>
                    </NavItem>

                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <Card body>
                                    <CardTitle></CardTitle>
                                    <CardText>
                                        <Container>
                                            <Row>
                                                <Col xs={6}>
                                                <span
                                                    className="chip">Libellé :</span> {roles.name}

                                                </Col>
                                            </Row>
                                            <br/>
                                        </Container>
                                    </CardText>
                                </Card>
                            </Col>
                        </Row>

                    </TabPane>
                    <TabPane tabId="2">
                        <Col sm="15">
                            <Card body>
                                <CardText>
                                    <Container>
                                        <p>Cliquer pour plus d'information :</p><br/>
                                        <Row>
                                            {permissions.length > 0 ? (permissions.map(permission => (
                                                <Col
                                                    xs={6}
                                                    body key={permission.id}
                                                >
                                                <span
                                                    className="chip"
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => {
                                                        setSelectedPermission(permission);
                                                        setSelectedApplicationModules(permission.applicationModules);
                                                        setBasicTitleAlert(!basicTitleAlert)
                                                    }}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    {permission.name}
                                                </span>
                                                </Col>
                                            ))) : 'Pas de Permission'}

                                        </Row>
                                    </Container>
                                </CardText>
                            </Card>
                        </Col>
                    </TabPane>
                </TabContent>

                {/* Partie modal permission*/}
                <SweetAlert
                    title="Permission détaille!"
                    show={basicTitleAlert}
                    onConfirm={() =>
                        setBasicTitleAlert(!basicTitleAlert)
                    }
                    confirmBtnCssClass="sweet-alert-confirm-button"
                    cancelBtnCssClass="sweet-alert-cancle-button"
                >
                    <Col>Code : {selectedPermission.name}</Col>
                    <Col>Utilisateur : {selectedPermission.name}</Col>
                    <Col>Utilisateurs : {selectedPermission.name}</Col>

                    {selectedApplicationModules.map(applicationModules =>
                        <div key={applicationModules.id}>
                            <p>{applicationModules.code} - {applicationModules.name}</p>
                        </div>
                    )}
                </SweetAlert>
            </div>
        )
    );
};
