
import React from "react";

export const BusinessDomainEdit = [
    {
        name: "code",
        label:"iam.businessDomains.code",
        placeholder:"Start with BD-...",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "name",
        label:"iam.businessDomains.name",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    }
];