
import { RoleColumn} from "../views/iam/role/data/role.column";
import RolesDetail from "../views/iam/role/role.detail";
import RoleForm from "../views/iam/role/RoleForm";

const RoleRoute = [

    {resource: "roles", type:"index",  host :`${process.env.REACT_APP_API_URL}/iam-service`, columns :RoleColumn, actions: [], detailComponent : RolesDetail},
    {resource: "roles", type:"new", host :`${process.env.REACT_APP_API_URL}/iam-service`, component:RoleForm},
    {resource: "roles", type:"update", host :`${process.env.REACT_APP_API_URL}/iam-service`, component:RoleForm},

];

export default RoleRoute;