import ApplicationModulesForm from "../views/iam/applicationmodules/ApplicationModulesForm";
import {ApplicationModuleColumn} from "../views/iam/applicationmodules/data/applicationmodule.column";
import ApplicationModuleDetail from "../views/iam/applicationmodules/applicationmodule.detail";
import {ApplicationModuleEdit} from "../views/iam/applicationmodules/data/applicationmodule.edit";


const ApplicationmoduleRoute = [

    {resource: "application_modules", type:"index",  host :`${process.env.REACT_APP_API_URL}/iam-service`, columns :ApplicationModuleColumn, actions: [], detailComponent : ApplicationModuleDetail},
    {resource: "application_modules", type:"new", host :`${process.env.REACT_APP_API_URL}/iam-service`, component: ApplicationModulesForm},
    {resource: "application_modules", type:"update", host :`${process.env.REACT_APP_API_URL}/iam-service`, component: ApplicationModulesForm},

];

export default ApplicationmoduleRoute;