import React, {useState} from "react";
import { AdaFilterSubHeader, AdaTable} from "@adaming/ada-react-component";
import {getAllUserWithPagination} from "../services/crud.service";
import AdaActions from "../adaaction/adaactions";


const AdaIndexResource = props => {

    const [resources, setResources] = React.useState([]);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);
    const [loadingModal, setLoadingModal] = React.useState(false);

    const paginationRowsPerPageOptions = [5,10,20,100]
    const defaultSizePerPage = paginationRowsPerPageOptions[1];
    const defaultPage = 0;
    const [currentPaginationPerRow, setCurrentPaginationPerRow] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxContent, setMaxContent] = useState(50);


    React.useEffect(() => {
        getAllUserWithPagination(props.host, props.context, handlerGetAllResult,currentPaginationPerRow,currentPage)
    }, [props.context, props.host, props.refresh]);

    React.useEffect(()=>{     
        getAllUserWithPagination(props.host, props.context, handlerGetAllResult,currentPaginationPerRow,currentPage)
    },[currentPaginationPerRow,currentPage])

    const handlerGetAllResult = (result) => {
        setResources(result.data);
        setFiltered(result.data);
        setMaxContent(result.headers['x-content-max'])
    };

    React.useEffect(() => {
        let actionsValue = props.actions; //Actions(context, handlerOpenModalDelete);
        actionsValue.forEach(action => {
            if(action.actionHandlerInte){
                action.actionHandler = () => {setLoadingModal(true); action.actionHandlerInte({"selectedRows" : selectedRows})};
            }
            if (action.patternEnable === undefined) {
                action.disabled = false;
            } else if (action.patternEnable === "1") {
                action.disabled = (selectedRows !== undefined && selectedRows.length !== 1);
            } else if (action.patternEnable === "1,*") {
                action.disabled = (selectedRows === undefined || selectedRows.length === 0);
            }
        });
        setActionsList(actionsValue);
    }, [props.actions, selectedRows, selectedRows.length]);


    const actionsComponent = (
        <AdaActions context={props.context} actionsObj={actionsList} selectedRows={selectedRows}/>
    );
    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={props.columns}
            items={resources}
            setFiltredItems={setFiltered}
        />
    );
    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
        props.setSelectedRows(state.selectedRows);
    }, [props]);
    return (<div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={props.columns}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                                paginationServer={true}
                                paginationTotalRows={maxContent}
                                paginationPerPage={currentPaginationPerRow}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangeRowsPerPage={(rowPerPage) =>{
                                    setCurrentPaginationPerRow(rowPerPage)
                                }}
                                onChangePage={page => {
                                    setCurrentPage(page - 1);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdaIndexResource;
